import React, { useContext } from "react"
import clsx from "clsx"
import { makeComponentStyles } from "../components/mixins"
import Container from "@components/Container/Container.jsx"
import MainWrap from "@components/Wrap/MainWrap"
import IconButton from "@components/Buttons/IconButton"
import ButtonLink from "@components/Buttons/ButtonLink"
import Layout from "../components/Layout/Layout"
import Chip from "@components/Cards/Chip.jsx"
import { Box, Typography, Grid, Avatar, makeStyles } from "@material-ui/core"
import infoLabel from "@static/images/Info.svg"
import { graphql } from "gatsby"
import Markdown from "markdown-to-jsx"
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../heplers.js"
import Seo from "@components/seo"

const styles = ({ palette, breakpoints }) => ({
  // <<<<<<<<<<<<<<<< Блок "Занятия спортом"  >>>>>>>>>>>>>>>>>>>>>
  sport__padding: {
    paddingBottom: "100px",
    [breakpoints.down("md")]: {
      paddingBottom: "25px"
    }
  },
  sport__container: {
    display: "flex",
    [breakpoints.down("md")]: {
      flexDirection: "column-reverse"
    }
  },
  sport__linker: {
    display: "contents",
    color: palette.primary.main,
    fontSize: "18px !important",
    lineHeight: "21px !important",
    [breakpoints.down("sm")]: {
      fontSize: "14px !important",
      lineHeight: "17px !important"
    }
  },
  sport__text: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 30,
    [breakpoints.down("md")]: {
      paddingRight: 0
    }
  },
  sport__image: {
    [breakpoints.down("md")]: {
      paddingBottom: "30px"
    }
  },
  sport__text_padding1: {
    paddingTop: "20px",
    [breakpoints.down("md")]: {
      paddingTop: "10px"
    }
  },
  sport__text_padding2: {
    paddingTop: "30px",
    [breakpoints.down("md")]: {
      paddingTop: "10px"
    }
  },
  // <<<<<<<<<<<<<<<< Блок "Физическое воспитание"  >>>>>>>>>>>>>>>>>>>>>
  phys_education__background: {
    backgroundColor: palette.primary.light,
    [breakpoints.down("sm")]: {
      backgroundColor: palette.white.main
    }
  },
  phys_education__desktop: {
    display: "flex",
    [breakpoints.down("sm")]: {
      display: "none"
    }
  },
  phys_education__mobile: {
    display: "none",
    [breakpoints.down("sm")]: {
      display: "flex"
    }
  },
  phys_education__text_box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  phys_education__text: {
    color: palette.text.navy_blue,
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "28px",
    [breakpoints.down("sm")]: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "17px"
    }
  },
  phys_education__sport_table: {
    display: "flex",
    justifyContent: "center",
    padding: "74px 0"
  },
  phys_education__table_row: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0",
    paddingLeft: "20px",
    borderTop: `1px solid ${palette.primary.main}`
  },
  phys__education__border_bottom: {
    borderBottom: `1px solid ${palette.primary.main}`
  },
  phys_education__table_text: {
    fontSize: "20px",
    lineHeight: "24px",
    color: palette.text.dark_blue
  },
  // <<<<<<<<<<<<<<<< Блок "Важно"  >>>>>>>>>>>>>>>>>>>>>
  important__header: {
    padding: "30px 0",
    [breakpoints.down("md")]: {
      paddingBottom: "16px"
    }
  },
  chiptext: {
    fontSize: "18px",
    [breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  important__container: {
    display: "flex",
    alignItems: "center"
  },
  important__image: {
    [breakpoints.down("md")]: {
      display: "none"
    }
  },
  important__text: {
    paddingRight: "67px"
  },
  // <<<<<<<<<<<<<<<< Блок "Места досуга"  >>>>>>>>>>>>>>>>>>>>>
  club__padding: {
    padding: "50px 0",
    [breakpoints.down("sm")]: {
      paddingBottom: "0px"
    }
  },
  club__card_container: {
    padding: "15px"
  },
  club__card_width: {
    display: "flex",
    [breakpoints.down("sm")]: {
      display: "flex",
      width: "310px"
    }
  },
  club__card: {
    display: "flex",
    padding: "20px 0",
    height: "172px",
    backgroundColor: palette.primary.light,
    [breakpoints.down("sm")]: {
      backgroundColor: palette.white.main,
      justifyContent: "center",
      padding: "0",
      height: "100px"
    }
  },
  club__icon_box: {
    width: "66px",
    height: "66px",
    marginLeft: "10px",
    "&::before": {
      content: "\"\"",
      position: "absolute",
      zIndex: 1,
      borderRadius: "50%",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundSize: "cover"
    }
  },
  club__card_header: {
    paddingBottom: "15px",
    [breakpoints.down("md")]: {
      paddingBottom: "5px"
    }
  },
  club__linker: {
    display: "contents",
    color: palette.primary.main
  },
  club__text: {
    fontSize: "20px !important",
    lineHeight: "24px !important",
    [breakpoints.down("md")]: {
      fontSize: "14px !important",
      lineHeight: "17px !important"
    }
  },
  club__text_padding: {
    paddingLeft: "30px",
    [breakpoints.down("md")]: {
      paddingLeft: "15px"
    }
  },
  // <<<<<<<<<<<<<<<< Блок "Футер"  >>>>>>>>>>>>>>>>>>>>>
  footer__card: {
    paddingTop: "10px",
    borderTop: `1px solid ${palette.primary.main}`,
    [breakpoints.down("sm")]: {
      border: "none"
    }
  },
  footer__header_text: {
    paddingBottom: "50px",
    [breakpoints.down("sm")]: {
      paddingBottom: "0px"
    }
  },
  // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
  desktop_text: {
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400"
  },
  mobile_text: {
    [breakpoints.down("sm")]: {
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "17px"
    }
  },
  bold_weight: {
    fontWeight: "500"
  },
  text_padding: {
    paddingLeft: "50px"
  }
})

function go(link) {
  window.open(link, "_blank")
}

const makeImage = (url) => makeStyles({
  root: {
    "&::before": {
      background: `url(${url}) center no-repeat`
    }
  }
})().root

const SportActivities = ({ data }) => {
  const {
    // <<<<<<<<<<<<<<<< Блок "Занятия спортом"  >>>>>>>>>>>>>>>>>>>>>
    sport__padding,
    sport__container,
    sport__linker,
    sport__image,
    sport__text,
    sport__text_padding1,
    sport__text_padding2,
    // <<<<<<<<<<<<<<<< Блок "Физическое воспитание"   >>>>>>>>>>>>>>>>>>>>>
    background,
    phys_education__background,
    phys_education__desktop,
    phys_education__mobile,
    phys_education__text_box,
    phys_education__text,
    phys_education__sport_table,
    phys__education__border_bottom,
    phys_education__table_row,
    phys_education__table_text,
    // <<<<<<<<<<<<<<<< Блок "Важно"  >>>>>>>>>>>>>>>>>>>>>
    important__header,
    chiptext,
    important__container,
    important__text,
    important__image,
    // <<<<<<<<<<<<<<<< Блок "Клубы"  >>>>>>>>>>>>>>>>>>>>>
    club__padding,
    club__card_container,
    club__card,
    club__card_width,
    club__icon_box,
    club__card_header,
    club__linker,
    club__text,
    club__text_padding,
    // <<<<<<<<<<<<<<<< Блок "Футер"  >>>>>>>>>>>>>>>>>>>>>
    footer__card,
    footer__header_text,
    // <<<<<<<<<<<<<<<< Общие классы  >>>>>>>>>>>>>>>>>>>>>
    desktop_text,
    mobile_text,
    bold_weight,
    text_padding
  } = makeComponentStyles(styles)

  const { language } = useContext(IntlContextConsumer)
  const intl = useIntl()
  const {
    ImportantImage,
    ImportantInfo,
    ImportantList,
    Info,
    InfoImage,
    MainList,
    SportClub,
    SportClubsInfo,
    SportOutsideInfo,
    SportOutsideList,
    SportSEO,
  } = getStrapiContentByLang(data.allStrapiSport.edges, language)

  return (
    <div>
      <Seo title={SportSEO?.TitleSEO}
           description={SportSEO?.DescriptionSEO}
           keywords={SportSEO?.KeywordsSEO.split(',')}
      />

      <>
        <Layout className={background}>
          {/*<<<<<<<<<<<<<<<< Блок "Занятия спортом"  >>>>>>>>>>>>>>>>>>>>>*/}
          <Container className={sport__padding} mw={"lg"}>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"} style={{ color: "#000" }}>
                  {intl.formatMessage({ id: "sport" })}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                <div className={sport__container}>
                  <div className={sport__text}>
                    <Typography className={clsx(desktop_text, mobile_text)}>
                      <Markdown>{Info}</Markdown>
                    </Typography>
                    {
                      MainList.map(({ Title, Description, MainListItemLink }, number) => (
                        <>
                          <Typography className={clsx(sport__text_padding1, desktop_text, mobile_text)}>
                            <span className={bold_weight}>{number + 1}. {Title}</span><br />
                            <Markdown>{Description}</Markdown>
                          </Typography>
                          {MainListItemLink ? <ButtonLink className={sport__linker} onClick={() => go(MainListItemLink.Link)}>
                            {MainListItemLink.Title}
                          </ButtonLink> : null}
                        </>
                      ))
                    }
                  </div>
                  <img className={sport__image} src={InfoImage?.localFile.publicURL} alt="photo" />
                </div>
              </MainWrap.Body>
            </MainWrap>
          </Container>
          {/*<<<<<<<<<<<<<<<< Блок Физическое воспитание  >>>>>>>>>>>>>>>>>>>>>*/}
          <Box className={phys_education__background}>
            <Container mw={"lg"}>
              <Grid container>
                <Box className={phys_education__desktop}>
                  <Grid className={phys_education__text_box} item xs={12} md={6}>
                    <Typography className={phys_education__text}>
                      <Markdown>{SportClubsInfo}</Markdown>
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Grid className={phys_education__sport_table} container>
                      {
                        SportClub.map(({ Title, Image }) => (
                          <Grid className={phys_education__table_row} item md={10}>
                            <img src={Image?.localFile.publicURL} alt="icon" />
                            <Typography
                              className={clsx(phys_education__table_text, text_padding, mobile_text)}>{Title}</Typography>
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Grid>
                </Box>
                {/*Версия для мобилки*/}
                <Box className={phys_education__mobile}>
                  <Grid item xs={12}>
                    <Typography className={mobile_text}>
                      {SportClubsInfo}
                      {
                        SportClub.map(({ Title, Image }, number) => (
                          <span>
                          {number}) {Title};<br />
                      </span>
                        ))
                      }
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            </Container>
          </Box>
          {/*<<<<<<<<<<<<<<<< Блок важно  >>>>>>>>>>>>>>>>>>>>>*/}
          <Container mw={"lg"}>
            <Grid container>
              <Grid className={important__header} item xs={12}>
                <Chip avatar={<Avatar src={infoLabel} />} label={intl.formatMessage({ id: "important" })} straight
                      className={chiptext} />
              </Grid>
              <Grid item xs={12} md={12}>
                <div className={important__container}>
                  <Typography className={clsx(important__text, desktop_text, mobile_text)}>
                    <Markdown>{ImportantInfo}</Markdown>
                  </Typography>
                  <img className={important__image} src={ImportantImage?.localFile.publicURL} alt="photo" />
                </div>
              </Grid>
            </Grid>
          </Container>
          {/*<<<<<<<<<<<<<<<< Блок клубы  >>>>>>>>>>>>>>>>>>>>>*/}
          <Container className={club__padding} mw={"lg"}>
            <Grid className={club__card_container} container spacing={3}>
              {
                ImportantList.map(({ Title, Phone, Image, Email, Site }) => (
                  <Grid item xs={12} md={4}>
                    <Box className={club__card}>
                      <div className={club__card_width}>
                        <IconButton className={clsx(club__icon_box, makeImage(Image?.localFile.publicURL))}
                                    color="blue" />
                        <div className={club__text_padding}>
                          <Typography className={clsx(club__text, club__card_header)}><span
                            className={bold_weight}>{Title}</span></Typography>
                          {Site ? <ButtonLink className={clsx(club__text, club__linker)}
                                              onClick={() => go(Site)}>
                            {intl.formatMessage({ id: "site" })}
                          </ButtonLink> : null}
                          {Phone ? <Typography
                            className={club__text}>{intl.formatMessage({ id: "phone" })}: {Phone}</Typography> : null}
                          {Email ? <Typography
                            className={club__text}>{intl.formatMessage({ id: "email" })}: {Email}</Typography> : null}
                        </div>
                      </div>
                    </Box>
                  </Grid>
                ))
              }
            </Grid>
          </Container>
          {/*<<<<<<<<<<<<<<<< Блок футер  >>>>>>>>>>>>>>>>>>>>>*/}
          <Container mw={"lg"}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={clsx(footer__header_text, desktop_text, mobile_text)}>
                  <Markdown>{SportOutsideInfo}</Markdown>
                </Typography>
              </Grid>
              <Grid container xs={12} spacing={4}>
                {
                  SportOutsideList.map(({ Title, Phone, Site, Description, Address, WorkingStart, WorkingEnd }, number) => (
                    <Grid item md={6} xs={12}>
                      <div className={footer__card}>
                        <Typography className={clsx(desktop_text, mobile_text)}>
                          <span className={bold_weight}>{number + 1}. {Title}</span>
                        </Typography>
                        {Description ? <Typography className={clsx(desktop_text, mobile_text)}>
                          <Markdown>{Description}</Markdown>
                        </Typography> : null}
                        <Typography className={clsx(desktop_text, mobile_text)}>
                          {intl.formatMessage({ id: "address" })}: {Address}
                        </Typography>
                        {Phone ? <Typography className={clsx(desktop_text, mobile_text)}>
                          {intl.formatMessage({ id: "phone" })}: {Phone}
                        </Typography> : null}
                        <Typography className={clsx(desktop_text, mobile_text)}>
                          {intl.formatMessage({ id: "workingtime" })}: {WorkingStart.substr(0, WorkingStart.lastIndexOf(":"))} - {WorkingEnd.substr(0, WorkingEnd.lastIndexOf(":"))}
                        </Typography>
                        {Site ? <ButtonLink className={clsx(club__text, club__linker)}
                                            onClick={() => go(Site)}>
                          {intl.formatMessage({ id: "site" })}
                        </ButtonLink> : null}
                      </div>
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </Container>
        </Layout>
      </>
    </div>
  )
}

export default SportActivities
export const pageQuery = graphql`{
  allStrapiSport {
    edges {
      node {
        locale
        ImportantImage {
          localFile {
            publicURL
          }
        }
        ImportantInfo
        ImportantList {
          Title
          Phone
          Image {
            localFile {
              publicURL
            }
          }
          Email
          Site
        }
        Info
        InfoImage {
          localFile {
            publicURL
          }
        }
        MainList {
          Title
          MainListItemLink {
            Title
            Link
          }
          Description
        }
        SportClub {
          Title
          Image {
            localFile {
              publicURL
            }
          }
        }
        SportClubsInfo
        SportOutsideInfo
        SportOutsideList {
          WorkingStart
          WorkingEnd
          Title
          Phone
          Site
          Description
          Address
        }
        SportSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        } 
      }
    }
  }
}`
